import { assign, filter, find, findIndex, forEach, get, isNil, map, merge, reverse, sumBy, uniq } from 'lodash';
import memoize from 'memoize-one';

import { OPERATORS } from '@core/models/Operator';

import DealStatus from '../enums/DealStatus';
import SectionError from '../enums/SectionError';
import SectionType, { HEADER_FOOTER_NUMBERING } from '../enums/SectionType';
import { getSafeKey } from '../utils/Generators';
import { formatOrder } from '../utils/OrderFormatter';
import { DATA_SOURCE_TYPES } from './AIPrompt';
import ActivityLog from './ActivityLog';
import { rxFootnotes } from './Content';
import { REQUIRED_TYPE } from './DealStyle';
import Diff from './Diff';
import { INDENT_TYPES } from './IndentationStyle';
import SectionStyle, { ALIGN } from './SectionStyle';
import TypeStyle, { NUMBER_INDENT } from './TypeStyle';
import { ValueType, VariableType } from './Variable';
import VariableFilter from './VariableFilter';
import Version, { BASE_ID } from './Version';

export const ROOT_ID = 'root';

export const CONDITION_OPERATORS = {
  AND: 'and',
  OR: 'or',
};

export const DEFAULT_CONDITONAL_GROUP = {
  groupOperator: CONDITION_OPERATORS.AND,
  individualOperator: CONDITION_OPERATORS.AND,
  conditions: [],
};

export default class Section extends ActivityLog {
  id = null;
  isDefault = false;
  sectiontype = null;
  displayname = null;
  content = null;
  hideOrder = false;
  subSectionType = null;
  headerFooterConfigKey = 'noPages';
  headerFooterConfigPages = null;
  headerFooterNumbering = 'written|total-number';
  pageBreakID = null;
  children = [];
  sourceChildren = [];
  variables = {};
  versions = [];
  candidate = null;
  conditions = [];
  conditionGroups = [];
  complexConditions = false;
  style = null;
  // Only populated manually when this Section is rendered inside of a SectionColumns container
  columnContainer = null;

  // Sections with a populated originCL property are linked to another section ("Clause Library")
  // And (for templates) will be readonly and dynamically updated automatically whenever the source section is changed
  originCL = null;
  // If excludeCL is set to true, this section will be excluded from CL search and therefore not usable as a source clause
  excludeCL = false;
  // Populating titleCL gives authors a way to label untitled sections and make them accessible/usable in the Clause Library
  titleCL = null;

  //if both are true, section will not be rendered (i.e., effectively deleted from contract)
  deleted = false; //set to true when user deletes with change tracking on
  deletionApproved = false; //set to true when user confirms deleted section
  pageBreak = false;

  extract = false;
  variableExtraction = null;

  computedPassesConditions = null;

  static createRoot() {
    return {
      id: ROOT_ID,
      sectiontype: SectionType.ROOT,
    };
  }

  constructor(json, deal) {
    const {
      activity,
      content,
      displayname,
      hideOrder,
      subSectionType,
      headerFooterConfigKey,
      headerFooterConfigPages,
      headerFooterNumbering = 'written|total-number',
      id,
      isDefault,
      order,
      parentid,
      sectiontype,
      sourceorder,
      sourceparentid,
      conditions,
      conditionGroups,
      complexConditions,
      style,
      deleted,
      deletionApproved,
      assigned,
      originCL,
      excludeCL,
      titleCL,
      pageBreak,
      extract,
      variableExtraction,
      pageBreakID,
    } = json;

    super(activity, deal, id);

    assign(this, {
      content,
      displayname,
      hideOrder,
      subSectionType,
      headerFooterConfigKey,
      headerFooterConfigPages,
      pageBreakID,
      id,
      isDefault,
      order,
      parentid,
      sectiontype,
      sourceorder,
      sourceparentid,
      assigned,
      originCL,
      excludeCL,
      titleCL,
      extract,
      variableExtraction,
    });

    if (headerFooterNumbering) {
      const numbering = headerFooterNumbering.split('|');
      this.headerFooterNumbering = find(HEADER_FOOTER_NUMBERING, { key: numbering[0], type: numbering[1] });
    }

    if (pageBreak) {
      this.pageBreak = pageBreak;
    }

    if (conditionGroups) {
      this.conditionGroups = conditionGroups;
    }

    if (complexConditions) {
      this.complexConditions = complexConditions;
    }

    if (deleted) this.deleted = true;
    if (deletionApproved) this.deletionApproved = true;

    let versions = [];

    // Transform content stored in Section model into a base Version,
    // so that there is always at least one Version (Section.currentVersion)
    // However, this logic enables a special case for ITEMs which are in the process of being added by users
    // And for normal SOURCE sections added on the fly furing Flow (ie not templated)
    // These are initially added to the Deal manually by users without any content in the model,
    // so as to create the placeholder Section object for rendering and expose title/body input UI
    // However once a first Version is saved by the user, we no longer want to include this "base" version,
    // as it would always show up as an empty V1 -- see Version.isPlaceholder
    if (displayname || content || !json.versions) {
      versions.push(
        new Version({
          id: BASE_ID,
          user: null,
          title: displayname || null,
          body: content || null,
        })
      );
    }

    if (json.versions) {
      forEach(json.versions, (v) => versions.push(new Version(v)));
    }
    this.versions = versions;

    // Now that we've got all Versions created, gather a superset of Diffs across all versions
    let diffs = [],
      diffIDs = [];
    forEach(this.versions, (version) => {
      const versionDiffs = Diff.getAll(this, version.body);
      diffs = diffs.concat(filter(versionDiffs, (diff) => !diffIDs.includes(diff.id)));
    });
    this.diffs = diffs;

    if (conditions) {
      this.conditions = map(conditions, (condition, key) => {
        return new VariableFilter(key, condition);
      });
    }

    // APPENDIX sections' style property is instantiated in the Appendix constructor
    // And is an instance of DealStyle (so as to define custom numbering etc for the whole appendix)
    if (sectiontype !== SectionType.APPENDIX) {
      this.style = new SectionStyle(style);
    }

    //add overriding styles if found
    forEach(style, (value, key) => {
      const typeStype = find(REQUIRED_TYPE, (type) => {
        return type.key === key;
      });
      if (typeStype) {
        this[key] = value;
      }
    });
  }

  get currentVersion() {
    return this.versions[this.versions.length - 1];
  }

  get priorVersion() {
    return this.versions.length > 1 ? this.versions[this.versions.length - 2] : null;
  }

  get activeFootnotes() {
    const footnotes = this.footnotesFromText;

    const activeFootnotes = map(this.activeFootnoteVariables, (footnote) => {
      const ftNumber = findIndex(footnotes, (ft) => {
        return ft === footnote.name;
      });
      if (footnote.deleting) delete footnote.deleting;
      footnote.ftNumber = formatOrder(ftNumber, this.deal.footnoteConfig.numberFormat);
      return footnote;
    });

    return activeFootnotes;
  }

  get activeFootnoteVariables() {
    return filter(this.variables, (variable) => {
      return variable.type === VariableType.FOOTNOTE && variable.val;
    });
  }

  get footnotesCount() {
    const footnoteMatches = [...this.displayBody.matchAll(rxFootnotes)];
    return footnoteMatches.length;
  }

  get footnotesFromText() {
    const footnoteMatches = [...this.displayBody.matchAll(rxFootnotes)];
    return uniq(
      map(footnoteMatches, (match) => {
        return match['1'];
      })
    );
  }

  //takes in the current editor state on the section and looks for removed footnotes.
  footnoteDiff = (editorState) => {
    const changedFootnotes = [...editorState.getCurrentContent().getPlainText().matchAll(rxFootnotes)];
    //Adding the deleting property to removed footnotes.
    //This is needed for the strikethrough on rendered footnotes.
    const footnoteDiff = map(this.activeFootnotes, (footnote) => {
      //check to see if there is one occurence of a active footnote.
      const found = find(changedFootnotes, (ft) => {
        return ft['1'] === footnote.name;
      });

      !found ? (footnote.deleting = true) : (footnote.deleting = false);
      return footnote;
    });
    return footnoteDiff;
  };

  //return a basic string version of the variable-replaced title content
  //we're not doing version tracking yet so using the "clean" version with no diffs
  get displayTitle() {
    return this.currentVersion.getText('title', true, this.deal.variables);
  }

  get displayBody() {
    return this.currentVersion.body.getPlainText();
  }

  // Enable Clause Library to search for either the actual title (displayname)
  // Or, if none exists, a manually specified one for lookup (titleCL)
  get indexCL() {
    if (this.isTemplateHeaderFooterSubSection) return this.sourceParent.titleCL || '';
    if (this.isTemplateHeaderFooter) return this.titleCL || '';

    return this.displayname || this.titleCL || '';
  }

  get passesConditions() {
    return this.memoPassesConditions(
      this.deleted,
      this.deletionApproved,
      this.sectiontype,
      this.conditions,
      this.complexConditions,
      this.conditionGroups,
      this.sourceParent,
      this.parent,
      this.deal.root,
      this.deal.variables
    );
  }

  memoPassesConditions = memoize(
    (
      deleted,
      deletionApproved,
      sectiontype,
      conditions,
      complexConditions,
      conditionGroups,
      sourceParent,
      parent,
      dealRoot,
      dealVariables
    ) => {
      //if this section has been deleted, it's gone!
      if (deleted && deletionApproved) return false;

      //if no condition data, go up parent hierarchy
      //should allow entire trees to be disabled...
      //use different hierarchy depending on sectiontype
      //both SOURCE and SUMMARY sections can be conditionally included!
      if (!conditions.length) {
        if (SectionType.src(sectiontype)) {
          if (!sourceParent || sourceParent == dealRoot) return true;
          else return sourceParent.passesConditions;
        } else {
          if (!parent || parent == dealRoot) return true;
          else return parent.passesConditions;
        }
      }

      if (complexConditions) {
        let groupPass = false;

        forEach(conditionGroups, (value, index) => {
          const group = filter(conditions, (condition) => {
            const found = find(value.conditions, (varName) => {
              return condition.variable === varName;
            });
            if (found) return condition;
          });

          //loop through each grouped condition and pass or fail it.
          const isAnd = value.individualOperator === CONDITION_OPERATORS.AND;
          let pass = isAnd;

          forEach(group, (condition) => {
            const { variable } = condition;

            //now we have a valid condition; make sure it references a real variable
            //if variable doesn't exist, or doesn't match required value, fail
            //if so, finally run the conditional check. if ANY condition fails, the whole check fails
            const v = dealVariables[variable];

            if (v == null && isAnd) return (pass = false);

            //Redacted conditions should fail unless they are known/unknown
            if (v?.isRedacted && ![OPERATORS.KNOWN.key, OPERATORS.UNKNOWN.key].includes(condition.operator.key))
              return (pass = false);

            if (!condition.test(v?.val, condition.isNumeric) && isAnd) return (pass = false);
            if (condition.test(v?.val, condition.isNumeric) && !isAnd) return (pass = true);
          });

          if (index > 0) {
            //Compare pass (current check) to the previous check (built up in groupPass).
            if (value.groupOperator === CONDITION_OPERATORS.AND) {
              groupPass = groupPass && pass;
            } else {
              groupPass = groupPass || pass;
            }
          }
          //first round always set group to pass
          else {
            groupPass = pass;
          }
        });

        return groupPass;
      } else {
        let pass = true;

        forEach(conditions, (condition) => {
          const { variable } = condition;

          //now we have a valid condition; make sure it references a real variable
          //if variable doesn't exist, or doesn't match required value, fail
          //if so, finally run the conditional check. if ANY condition fails, the whole check fails
          const v = dealVariables[variable];

          if (v == null) return (pass = false);

          if (v.isRedacted && ![OPERATORS.KNOWN.key, OPERATORS.UNKNOWN.key].includes(condition.operator.key))
            return (pass = false);

          if (!condition.test(v.val, condition.isNumeric)) return (pass = false);
        });
        return pass;
      }
    }
  );

  get status() {
    let substatuses = [];
    let items;

    switch (this.sectiontype) {
      //legacy
      case SectionType.PARTIES:
        return DealStatus.AGREED;
      //for Scope, only consider ITEM children as valid
      //there must be at least one present to be considered complete
      case SectionType.SCOPE:
        items = filter(this.children, (itm) => itm.sectiontype === SectionType.ITEM && itm.currentVersion.hasContent);
        if (items.length > 0) {
          items.map((s) => substatuses.push(s.activityStatus));
        } else {
          return DealStatus.TODO;
        }
        break;
      //Payments are similar to Scope -- there must be at least
      //one valid payment with todo == 0 (i.e., valid amount)
      case SectionType.PAYMENT:
        const payments = filter(this.deal.payments, (pmt) => pmt.todo == 0);
        if (payments.length > 0) {
          this.deal.payments.map((p) => substatuses.push(p.activityStatus));
        } else {
          return DealStatus.TODO;
        }
        break;
      //'CONTENT' is a legacy type (no longer used as of ~2/10/2017)
      case 'CONTENT':
        this.children.map((ch) => substatuses.push(ch.status));
        break;
      //summary section is a text-based (child) content section
      //i.e., 1 level "down", the main content the user reads
      //as opposed to instead of source
      //its children (if there are any) are source sections
      case SectionType.SUMMARY:
        if (this.todo > 0) return DealStatus.TODO;
        else if (this.children) {
          //only look at statuses of included child sections
          const included = this.children.filter((src) => src.passesConditions);
          included.map((src) => substatuses.push(src.status));
        } else return this.activityStatus;
        break;
      case SectionType.SOURCE:
      case SectionType.ITEM:
        if (this.todo > 0) return DealStatus.TODO;
        else return this.activityStatus;
      case SectionType.LIST:
        if (this.todo > 0) return DealStatus.TODO;
        else {
          this.items.map((itm) => substatuses.push(itm.status));
        }
        break;
      default:
        return this.activityStatus;
    }

    if (substatuses.length > 0) {
      //if there is ANYTHING left ToDo, show ToDo
      if (filter(substatuses, DealStatus.TODO).length > 0) return DealStatus.TODO;
      //if ANYTHING needs discussion, show that
      if (filter(substatuses, DealStatus.DISCUSS).length > 0) return DealStatus.DISCUSS;
      //if ALL are agreed, show agreed
      if (filter(substatuses, DealStatus.AGREED).length == substatuses.length) return DealStatus.AGREED;

      //if ALL are complete, show complete
      if (filter(substatuses, DealStatus.COMPLETE).length == substatuses.length) return DealStatus.COMPLETE;

      //if ANY are ToReview, show ToReview
      if (filter(substatuses, DealStatus.REVIEW).length > 0) return DealStatus.REVIEW;

      //if ANY are proposed (at this point others must be agreed or none)
      if (filter(substatuses, DealStatus.PROPOSED).length > 0) return DealStatus.PROPOSED;
    }
    //if we get here, assume agreement
    return DealStatus.AGREED;
  }

  get todo() {
    const countEmptyVars = (vars) =>
      filter(vars, (v) => {
        // PROTECTED (Secret) vars don't count as empty in this context because they can be filled in after signing
        if (v.type === VariableType.PROTECTED) return false;

        // TABLE vars are always arrays (even when empty) so check length
        if (v.valueType === ValueType.TABLE && !isNil(v.val)) return v.val?.length === 0;

        // Normal case (all other SIMPLE vars), either null OR empty string is empty
        return isNil(v.val) || v.val === '';
      }).length;

    let items = [],
      selfTodo = 0,
      childTodo = 0;

    switch (this.sectiontype) {
      // Legacy
      case SectionType.PARTIES:
        return 0;
      // Payment appendices require at least 1 valid payment (>$0)
      case SectionType.PAYMENT:
        return sumBy(this.deal.payments, 'amount') > 0 ? 0 : 1;
      // Scope appendices require at least 1 user-entered child (ITEM)
      case SectionType.SCOPE:
        items = filter(this.children, (itm) => itm.sectiontype === SectionType.ITEM && itm.currentVersion.hasContent);
        return items.length > 0 ? 0 : 1;
      // List sections may not be configured as required; if so, use same logic as Scope (all children are ITEMS so don't need that check)
      case SectionType.LIST:
        selfTodo = countEmptyVars(this.variables);
        items = filter(this.items, (itm) => itm.currentVersion.hasContent);
        if (items.length > 0) {
          items.map((itm) => (childTodo += itm.todo));
        } else {
          // If the list is marked as required and there is no user-entered content yet, count it as something todo
          if (this.required) selfTodo += 1;
        }
        return selfTodo + childTodo;
      //content sections have children (SUMMARY sections); derive todo from children
      //summary sections can have their own variables AND child SOURCE sections
      case SectionType.CONTENT:
      case SectionType.SUMMARY:
        //only count sections as todo if they are included in current conditional checks
        items = this.children.filter((src) => src.passesConditions);
        items.map((src) => (childTodo += src.todo));
        //all of a section's variables must be populated in order to be complete
        selfTodo = countEmptyVars(this.variables);
        return childTodo + selfTodo;
      //this covers actual sections on the contract
      case SectionType.SOURCE:
      case SectionType.ITEM:
      default:
        //here too, only count as unfinished if this section is included
        if (this.passesConditions) return countEmptyVars(this.variables);
        else return 0;
    }
  }

  get showOrder() {
    if (this.deleted) return false;
    switch (this.sectiontype) {
      case SectionType.SOURCE:
      case SectionType.LIST:
        return !this.hideOrder;
      case SectionType.ITEM:
        return !this.hideOrder && get(this, 'numberFormat.type') !== 'none';
      case SectionType.APPENDIX:
      case SectionType.SIGNATURE:
        return false;
      case SectionType.SUMMARY:
        if (this.indentLevel == 0 || !this.parent) return false;
        else return !this.parent.hideOrder;
      default:
        return false;
    }
    // return !this.hideOrder && this.sectiontype == 'SOURCE';
  }

  // Check the sections style to see if it is unordered.
  // This is used to determine if we need to remove the overriding section numbering and use the deal.style.numbering
  get isUnordered() {
    if (!this.isSource) return false;
    const unordered = this.style.numbering ? this.style.numbering[0].type === 'unordered' : false;
    return unordered;
  }

  get displayNumber() {
    let full = true,
      sec = this;
    if (SectionType.src(this.sectiontype)) {
      //only show the full number (e.g., 2.4.1) if all levels up to and including this one are numeric
      //unless the parent is of type APPENDIX. Since appendix is not numbered yet holds formatting in numberFormat for its children we return undefined from _.get(sec, 'numberFormat.type').
      while (sec && !sec.isRoot) {
        if (get(sec, 'numberFormat.type') !== 'number' && sec.sectiontype !== 'APPENDIX') {
          full = false;
          break;
        }
        sec = sec.sourceParent;
      }
      return this.sourceNumber(full);
    } else if (this.sectiontype === SectionType.ITEM) {
      if (get(this, 'list.continuous')) {
        while (sec && !sec.isRoot) {
          if (get(sec, 'numberFormat.type') !== 'number' && sec.sectiontype !== 'APPENDIX') {
            full = false;
            break;
          }
          sec = sec.isItem ? sec.parent : sec.sourceParent;
        }
      } else {
        full = false;
      }
      return this.sourceNumber(full);
    } else {
      //non Source sections (i.e., Overview sections) just have a simple number format, e.g., "3."
      const index = filter(this.parent?.children, { passesConditions: true }).indexOf(this);
      if (index > -1) return `${index + 1}.`;
      //if section doesn't pass conditions (i.e., showing ALL conditional sections in editor), just show #
      else return '#';
    }
  }

  get displayReference() {
    let num = typeof this.sourceNumber === 'function' ? this.sourceNumber(true) : '';

    // Shave off trailing . for display
    if (num.endsWith('.')) num = num.substring(0, num.length - 1);

    // If there's no number, but we do have a title, use that
    if (!num && this.displayTitle) {
      return `Section: ${this.displayTitle}`;
    } else {
      return num ? `Section ${num}` : `untitled section`;
    }
  }

  get numberingLevel() {
    if (this.isCaption) return 0;

    let num = -1;
    let sec = this;
    while (sec != null) {
      num += 1;
      sec = [SectionType.SOURCE, SectionType.LIST].includes(sec.sectiontype) ? sec.sourceParent : sec.parent;
      if (sec && [SectionType.ROOT, SectionType.APPENDIX, SectionType.SIGNATURE].includes(sec.sectiontype)) break;
    }

    return num;
  }

  get themeIndentation() {
    let num = this.numberingLevel;

    if (!this.deal?.style) return null;

    const indentation = this.deal.style?.indentation[num + 1] || null;

    return indentation;
  }

  get indentLevel() {
    let num = this.numberingLevel;

    if (this.themeIndentation) {
      return this.themeIndentation.override && this.themeIndentation.forcedLevel !== 'none'
        ? this.themeIndentation.forcedLevel
        : num;
    }

    return num;
  }

  get canIndent() {
    if ([SectionType.SOURCE, SectionType.LIST].includes(this.sectiontype)) return true;

    // ITEM sections are children of either LIST sections or legacy SCOPE sections
    // Only LISTs support indentation, and it's configurable to the LIST instance
    if (this.isItem && get(this, 'list.nesting')) return true;

    return false;
  }

  get canTrack() {
    if (!SectionType.trackable(this.sectiontype) && !this.isAI) return false;
    if (this.isItem) {
      if (!this.list || !this.list.redlining) return false;
      if (this.versions.length === 1 && this.currentVersion.id === BASE_ID) return false;
    }
    return true;
  }

  get canCL() {
    return SectionType.cl(this.sectiontype) && !this.isCaption;
  }

  get sibs() {
    return this.isSource ? get(this, 'sourceParent.sourceChildren', []) : get(this, 'parent.children', []);
  }

  get numberedSibs() {
    const numberedSibs = filter(this.sibs, { showOrder: true, passesConditions: true });
    if (this.isSource) {
      const continuousSibs = [];

      // Go through "real" sibs; any lists we find that are marked as continuous, interpolate their children
      forEach(numberedSibs, (sib) => {
        if (sib.isList && sib.continuous) {
          /*
          // The following code would enable empty lists with continuous numbering on to still "hold" a spot in the numbering
          // This makes for a slightly nicer UX for the document owner during Flow,
          // where the empty list still occupies a number with its "Add item" prompt,
          // (which then gets replaced once the first item is added)
          // However it creates UX problems exported to docx/pdf, or viewed in Flow by a non-editor user who can't add items:
          // a) either we'd need to render an empty, numbered section (with an "Intentionally left blank" message???)
          // b) or we wouldn't render, in which case doc numbering for viewer/docx/pdf will be different from a Flow editor
          // Either of these cases is very problematic from a legal perspective,
          // so we can't consider an empty list as part of the numbering system until it has (real) children

          if (sib.isNumberPlaceholder) continuousSibs.push(sib);
          else continuousSibs.push(...sib.children);
          */
          continuousSibs.push(...sib.children);
        } else {
          continuousSibs.push(sib);
        }
      });

      return continuousSibs;
    } else {
      return numberedSibs;
    }
  }

  //determine whether this section can be moved in the specified hierarchical direction
  canMove(dir, source) {
    const self = this.layoutSection;

    if (source) {
      if (!SectionType.src(self.sectiontype) || self.sourceParent == null) return false;
    } else {
      if (SectionType.src(self.sectiontype) || self.parent == null) return false;
    }

    const sibs = source ? self.sourceParent.sourceChildren : self.parent.children;
    const parent = source ? self.sourceParent : self.parent;
    const currentIndex = sibs.indexOf(self);
    let prevSib, nextSib;

    // When moving sections up/down, we need to ensure that special sections (SIGNATURE/APPENDICES) remain contiguous
    // Since they can only be added to the end of the doc, we don't need an additional check for where they fall within siblings
    switch (dir) {
      case 'up':
        if (currentIndex > 0) {
          prevSib = sibs[currentIndex - 1];
          return (!this.isSpecial && !prevSib.isSpecial) || (this.isSpecial && prevSib.isSpecial);
        } else {
          return false;
        }
      case 'down':
        if (currentIndex < sibs.length - 1) {
          nextSib = sibs[currentIndex + 1];
          return (!this.isSpecial && !nextSib.isSpecial) || (this.isSpecial && nextSib.isSpecial);
        } else {
          return false;
        }
      case 'right':
        // Only allow indentation (ie for current section to become child of previous section) if all of the following are true:
        // 1. Section type is capable of indentation (limited to SOURCE and LIST)
        // 2. It's not the first sib, so there's a previous section to become parent
        if (self.canIndent && currentIndex > 0) {
          // 3a. Extra condition for ITEM sections in a LIST where nesting can be explicitly disabled
          if (self.isItem && self.list) {
            return self.list.nesting;
          } else {
            // 3b. For normal SOURCE items, make sure previous section is capable of having standard sourceChildren (ie not a LIST)
            prevSib = sibs[currentIndex - 1];
            return [SectionType.SOURCE, SectionType.APPENDIX, SectionType.SIGNATURE].includes(
              get(prevSib, 'sectiontype')
            );
          }
        }
        // Summary body sections can become orphaned if their parent block is deleted -- enable fixing
        else if (self.modelError === SectionError.ORPHANED_SUMMARY) {
          return true;
        } else {
          return false;
        }
      case 'left':
        // Disallow outdenting past root
        if (parent.isRoot) return false;
        // Allow (mistaken) nested special sections (APPENDIX/SIGNATURE) to be outdented (to fix them)
        if (this.isSpecial) return true;
        // Disallow orphaning of special sections
        if (parent.isSpecial) return false;
        // Disallow LIST/CAPTION items to escape the LIST/CAPTION
        if (parent.isList || this.isCaption) return false;
        // Finally if we get here, allow outdenting if currently indented
        return self.indentLevel > 0;
    }
  }

  //if this is part of an appendix section, return a reference to that section
  //if this IS the (top-level) appendix section, return self
  get appendix() {
    switch (this.sectiontype) {
      case SectionType.APPENDIX:
      case SectionType.SIGNATURE:
        return this;
      case SectionType.SOURCE:
      case SectionType.LIST:
        let parent = this.sourceParent;
        while (parent != null && parent != this.deal.root) {
          if ([SectionType.APPENDIX, SectionType.SIGNATURE].includes(parent.sectiontype)) return parent;
          else parent = parent.sourceParent;
        }
        return null;
      case SectionType.ITEM:
        return this.parent ? find(this.parent.children, { sectiontype: SectionType.APPENDIX }) : null;
      default:
        return null;
    }
  }

  //If this Section is inside a CAPTION, refer to the parent CAPTION Section for functions having to do with layout
  //eg moving the entire Caption around and managing properties of the parent (CAPTION)
  //otherwise (ie for 99.99% of Sections), just return self
  get layoutSection() {
    if (get(this, 'sourceParent.sectiontype') === SectionType.CAPTION) return this.sourceParent;
    else return this;
  }

  //convert conditions array back to object format for storage
  get conditionsJSON() {
    if (!this.conditions.length) return null;

    const json = {};

    forEach(this.conditions, (condition) => {
      json[condition.variable] = condition.json;
    });

    return json;
  }

  get references() {
    return filter(this.variables, { type: VariableType.REF });
  }

  //helper getters for conditional rendering throughout various components
  get isSource() {
    return SectionType.src(this.sectiontype);
  }
  get isSummary() {
    return this.sectiontype === SectionType.SUMMARY;
  }
  get isHeader() {
    return this.sectiontype === SectionType.HEADER;
  }

  get isSignature() {
    return this.sectiontype === SectionType.SIGNATURE;
  }
  get isAppendix() {
    return this.sectiontype === SectionType.APPENDIX;
  }
  get isList() {
    return this.sectiontype === SectionType.LIST;
  }
  get isAI() {
    return this.sectiontype === SectionType.LIST && ['AI', 'TIMELINE'].includes(this.subType);
  }
  get topLevelAIBlock() {
    return this.parent?.isAIList ? this.parent : this;
  }
  get isAIList() {
    return this.isAI && get(this, 'aiPrompt.responseType') === 'list';
  }
  get isTimeline() {
    return this.sectiontype === SectionType.LIST && this.subType === 'TIMELINE';
  }
  get timelineVarName() {
    return 'TL-' + getSafeKey(this.name, false, true);
  }
  get timelineVar() {
    return this.isTimeline ? this.deal.variables[this.timelineVarName] : null;
  }
  get timelineVarData() {
    return this.timelineVar?.tableValueFormatted || [];
  }
  get isVinnie() {
    return this.isAI && get(this, 'aiPrompt.engine.key') === 'vinnie';
  }
  get isItem() {
    return this.sectiontype === SectionType.ITEM;
  }
  get isBlock() {
    return this.isItem && get(this, 'list.subType') === 'BLOCK';
  }
  get isTemplate() {
    return this.deal.isTemplate;
  }
  get shouldSyncWithCL() {
    return this.isTemplate && !!this.originCL;
  }
  get hasConditions() {
    return this.conditions.length > 0;
  }
  get isCaption() {
    return this.sectiontype === SectionType.CAPTION || get(this, 'sourceParent.sectiontype') === SectionType.CAPTION;
  }

  get isTemplateHeaderFooterSubSection() {
    return [SectionType.TEMPLATE_FOOTER, SectionType.TEMPLATE_HEADER].includes(get(this, 'sourceParent.sectiontype'));
  }

  get isTemplateHeaderFooter() {
    return this.sectiontype === SectionType.TEMPLATE_HEADER || this.sectiontype === SectionType.TEMPLATE_FOOTER;
  }

  get isTemplateHeader() {
    return this.sectiontype === SectionType.TEMPLATE_HEADER;
  }

  get isTemplateFooter() {
    return this.sectiontype === SectionType.TEMPLATE_FOOTER;
  }

  get isColumn() {
    return get(this, 'style.columns');
  }
  get isSpecial() {
    return SectionType.special(this.sectiontype);
  }

  // Catch-all getter to find the correct ROOT section,
  // which should be the parent/sourceParent for ALL Sections (both Contract/Overview types)
  // Some *very* old templates/contracts may not have had an id of 'root' so we can only use sectiontype
  get isRoot() {
    const sectiontype = get(this, 'sectiontype', '');
    return sectiontype.toUpperCase() === SectionType.ROOT;
  }

  get isCell() {
    return this.sectiontype === SectionType.SOURCE && this.isCaption;
  }

  get styleTypes() {
    let types = null;

    switch (this.sectiontype) {
      case SectionType.SUMMARY:
        types = filter(REQUIRED_TYPE, (type) => {
          if (this.indentLevel === 0) {
            return type.sectionType === SectionType.SUMMARY && type.key !== 'OverviewBody';
          } else {
            return type.sectionType === SectionType.SUMMARY && type.key === 'OverviewBody';
          }
        });
        return types;
      case SectionType.HEADER:
        types = filter(REQUIRED_TYPE, (type) => {
          return type.key === this.headerType.toUpperCase();
        });
        return types;
      case SectionType.APPENDIX:
        types = filter(REQUIRED_TYPE, (type) => {
          return type.sectionType === SectionType.APPENDIX;
        });
        return types;
      case SectionType.SIGNATURE:
        types = filter(REQUIRED_TYPE, (type) => {
          return type.sectionType === SectionType.SIGNATURE;
        });
        return types;
      case SectionType.TEMPLATE_FOOTER:
        types = filter(REQUIRED_TYPE, (type) => {
          return type.sectionType === SectionType.TEMPLATE_FOOTER;
        });
        return types;
      case SectionType.TEMPLATE_HEADER:
        types = filter(REQUIRED_TYPE, (type) => {
          return type.sectionType === SectionType.TEMPLATE_HEADER;
        });
        return types;
      case SectionType.SOURCE:
      default:
        types = filter(REQUIRED_TYPE, (type) => {
          return type.sectionType === SectionType.SOURCE;
        });
        return reverse(types);
    }
  }

  //This applies the section style ovverrides if there are any
  mergeStyles(type) {
    const styles = this.deal.style.type;
    const copy = merge({}, styles[type]);

    let override;

    //e.g. List Items should inherit styles from the parent (List Section)
    switch (this.sectiontype) {
      case SectionType.ITEM:
        override = this.parent[type] || {};
        if (this.parent.style.isAligned) override.alignment = this.parent.style.align;
        break;
      default:
        override = this[type] || {};
    }

    if (override) {
      const merged = merge(copy.raw.native, override);
      copy.raw['native'] = merged;
      const mergedStyles = new TypeStyle(copy.raw, copy.name);
      return mergedStyles;
    } else {
      return styles[type];
    }
  }

  get footnoteStyle() {
    return this.mergeStyles('FootnoteBody');
  }

  get styleTitle() {
    switch (this.sectiontype) {
      case SectionType.SUMMARY:
        return this.mergeStyles('OverviewTitle');
      case SectionType.HEADER:
        return this.mergeStyles(this.headerType.toUpperCase());
      case SectionType.APPENDIX:
        return this.mergeStyles('AppendixTitle');
      case SectionType.SIGNATURE:
        return this.mergeStyles('SignatureTitle');
      case SectionType.SOURCE:
      default:
        return this.mergeStyles('SectionTitle');
    }
  }

  get styleNumber() {
    const styles = this.deal.style.type;
    if (this.sectiontype === SectionType.SUMMARY) {
      return styles.OverviewNumber;
    } else {
      return styles.SectionNumber;
    }
  }

  get styleBody() {
    if (this.isTemplateHeaderFooterSubSection) {
      return this.sourceParent.styleBody;
    }

    switch (this.sectiontype) {
      case SectionType.SUMMARY:
        if (this.indentLevel === 0) {
          return this.mergeStyles('OverviewSubtitle');
        } else {
          return this.mergeStyles('OverviewBody');
        }
      case SectionType.APPENDIX:
        return this.mergeStyles('AppendixSubtitle');
      case SectionType.SIGNATURE:
        return this.mergeStyles('SignatureSubtitle');
      case SectionType.TEMPLATE_FOOTER:
        return this.mergeStyles('Footer');
      case SectionType.TEMPLATE_HEADER:
        return this.mergeStyles('Header');
      case SectionType.SOURCE:
      default:
        return this.mergeStyles('SectionBody');
    }
  }

  get hasNumberAlignmentOverride() {
    return (
      [ALIGN.CENTER, ALIGN.RIGHT].includes(this.alignment) && this.showOrder && this.sectiontype === SectionType.SOURCE
    );
  }

  get isEmpty() {
    return !this.hasBodyText && this.displayTitle.length === 0;
  }

  get hasBodyText() {
    let bodyText = '';
    if (this.content) {
      bodyText = this.content.replaceAll('<p>', '');
      bodyText = bodyText.replaceAll('</p>', '');
      bodyText = bodyText.replaceAll('\n', '');
    }
    return bodyText.length > 0;
  }

  // If this is a SUMMARY section on Overview, get a count of the number of SOURCE section it is linked to
  get summaryCount() {
    if (this.isSummary) return filter(this.children, { sectiontype: SectionType.SOURCE }).length;
    else return 0;
  }

  // Inspect theme styles to see what the default text alignment should be for this section
  get themeAlignment() {
    switch (this.sectiontype) {
      case SectionType.SUMMARY:
      case SectionType.HEADER:
      case SectionType.APPENDIX:
      case SectionType.SIGNATURE:
        return get(this, 'styleTitle.native.alignment') || null;
      case SectionType.SOURCE:
      default:
        return get(this, 'styleBody.native.alignment') || null;
    }
  }

  get alignment() {
    switch (this.sectiontype) {
      case SectionType.APPENDIX:
      case SectionType.SIGNATURE:
        return get(this, 'sectionStyle.align') || this.themeAlignment;
      case SectionType.SUMMARY:
      case SectionType.HEADER:
      case SectionType.SOURCE:
      default:
        return get(this, 'style.align') || this.themeAlignment;
    }
  }

  // Web-only (unlike prior 3 getters) helper method to compile a React style object to apply to Section components
  get webLayout() {
    const { layout } = this.deal.style;

    // Add bottom margin to section unless it's inside a Caption (which has its own spacing applied)
    // should exclude Columns too if/when we enable borders and padding on Columns
    let style = {
      marginBottom: get(this, 'sourceParent.sectiontype') === SectionType.CAPTION ? null : layout.Section.web.bottom,
    };

    if (
      this.columnContainer &&
      this.columnContainer.sections.indexOf(this) + 1 < this.columnContainer.sections.length
    ) {
      style.marginRight = layout.Column.web.right;
    }

    // Merge in Section-level style which gets applied to the wrapping div class for borders and padding
    merge(style, this.style.css);

    // Summary sections don't get indented
    // For normal (Source) sections, there will already be a paddingLeft value of 0
    // (unless otherwise specified in Caption Cell formatting)
    // So add on the structural indent, which is also achieved with padding
    if (this.indentLevel > 0 && !this.isSummary && !isNaN(get(layout, 'Indent.web.left'))) {
      if (typeof style.paddingLeft === 'string') {
        style.paddingLeft = parseInt(style.paddingLeft) || 0;
      }
    }

    if (this.themeIndentation?.override) {
      style.paddingLeft = layout.Indent.web.left * this.themeIndentation.forcedLevel;
    } else {
      style.paddingLeft += layout.Indent.web.left * this.indentLevel;
    }

    // Special case for ITEM BLOCKs under numbered lists;
    // they aren't indented but we need to manually add padding to account for list number
    if (get(this, 'list.subType') === 'BLOCK' && get(this, 'list.showOrder') === true) {
      style.paddingLeft += NUMBER_INDENT;
    }

    // Somehow certain sections (seems limited to Appendix/Signature) still get invalid padding applied, despite logic above
    // This could be due to legacy values in theme. It results in a React error, so null it out to be safe
    if (isNaN(style.paddingLeft)) style.paddingLeft = null;

    return style;
  }

  get webContentLayout() {
    let { layout } = this.deal.style;
    let styleBody = { gridColumn: 2, gridRow: 2, textIndent: 0 };

    if (!this.displayTitle && !this.isList) styleBody.gridRow = 1;

    console.log('webContentLayout', this.themeIndentation);

    if (this?.themeIndentation && !this.hasNumberAlignmentOverride) {
      styleBody.border = '1px solid #f0f';

      if (this.themeIndentation.type === INDENT_TYPES.INDENT_HANGING) {
        console.log('HANGING!', this.displayTitle, this.indentLevel, this.themeIndentation);
        styleBody.border = '1px solid #f00';
        if (this.themeIndentation.wrap === 'none') {
          styleBody.border = '2px outdent #f00'; // Is this used?
          styleBody.marginLeft = layout.Indent.web.left;
          styleBody.textIndent = -styleBody.marginLeft;
        } else if (this.themeIndentation.wrap === this.indentLevel) {
          // Is this used?
          // styleBody.border = '2px dashed #f00';
          // if (!this.displayTitle) {
          //   styleBody.border = '2px double #f00';
          //   styleBody.gridRow = 1;
          //   styleBody.gridColumn = 2;
          // }
        } else if (this.indentLevel > this.themeIndentation.wrap) {
          styleBody.border = '2px dashed #f00';
          if (this.displayNumber) {
            styleBody.marginLeft = -layout.Indent.web.left * Math.abs(this.indentLevel - this.themeIndentation.wrap);
            styleBody.textIndent = -styleBody.marginLeft;
          } else {
            styleBody.marginLeft = -layout.Indent.web.left * Math.abs(this.indentLevel - this.themeIndentation.wrap);
            styleBody.textIndent = -styleBody.marginLeft;
          }
        } else {
          styleBody.border = '2px dotted #f00';

          if (this.displayNumber) {
            styleBody.marginLeft = layout.Indent.web.left * Math.abs(this.indentLevel - this.themeIndentation.wrap);
            styleBody.textIndent = -styleBody.marginLeft;
          } else {
            styleBody.marginLeft = layout.Indent.web.left * Math.abs(this.indentLevel - this.themeIndentation.wrap);
            styleBody.textIndent = -styleBody.marginLeft;
          }
        }
      } else if (this.themeIndentation.type === INDENT_TYPES.INDENT_FIRST_LINE) {
        styleBody.border = '2px solid #0f0';
        console.log('first line!', this.displayTitle, this.indentLevel, this.themeIndentation);

        if (this.displayNumber) {
          styleBody.marginLeft = layout.Indent.web.left * (this.themeIndentation.wrap - this.indentLevel);
          styleBody.textIndent = -(styleBody.marginLeft - layout.Indent.web.left * (this.displayTitle ? 1 : 0));
        } else {
          styleBody.textIndent = layout.Indent.web.left;
        }
      } else if (this.themeIndentation.type === INDENT_TYPES.NONE) {
        console.log('NONE!', this.displayTitle, this.indentLevel, this.themeIndentation);
        styleBody.border = '1px solid #00f';

        if (this.indentLevel > this.themeIndentation.wrap) {
          styleBody.border = '2px dotted #00f';
          styleBody.marginLeft = -layout.Indent.web.left * (this.indentLevel - this.themeIndentation.wrap);
          styleBody.textIndent = -styleBody.marginLeft;
        } else {
          styleBody.border = '2px dashed #00f';
          // styleBody.marginLeft = layout.Indent.web.left * (this.themeIndentation.wrap - this.indentLevel);
          styleBody.textIndent = -styleBody.marginLeft;
        }
        // if (!this.displayTitle) {
        //   styleBody.border = '2px dotted #00f';
        //   if (this.themeIndentation.wrap !== 'none') {
        //     styleBody.border = '2px dashed #00f';
        //     // styleBody.marginLeft += layout.Indent.web.left;
        //   }
        // }
      }
    }

    console.log({ styleBody });

    if (typeof document !== 'undefined' && !document.cookie.split('; ').includes('debug=true')) {
      delete styleBody.border;
    }

    return styleBody;
  }

  // Due to legacy UI and section types which previously didn't limit certain paths in order to enforce model structure,
  // It's possible to end up with structural errors in the sections which (mostly) fail invisibly
  // but can have unpredictable consequences (e.g., things not rendering propertly in pdf, etc)
  // This getter makes it easy to self-monitor and prompt users in Draft for how to correct these errors
  get modelError() {
    const isAtRoot = get(this.sourceParent, 'isRoot') || get(this.parent, 'isRoot');
    const hasImageOrTable = find(this.variables, ({ valueType }) =>
      [ValueType.IMAGE, ValueType.TABLE].includes(valueType)
    );

    if (hasImageOrTable && this.alignment !== ALIGN.LEFT) {
      return SectionError.VARIABLE_ALIGNMENT;
    }

    // First, make sure all special sections (SIGNATURE / APPENDIX) are at root, not children of other sections
    if (this.isSpecial && !isAtRoot) {
      return SectionError.NESTED_APPENDIX;
    }

    // Next check for the inverse: orphaned non-special sections that are not children of special sections
    const sibs = this.sibs;
    const idx = sibs.indexOf(this);
    if (this.isSummary) {
      if (isAtRoot && !this.currentVersion.has('title') && this.currentVersion.has('body')) {
        return SectionError.ORPHANED_SUMMARY;
      }
    } else {
      const firstSpecial = findIndex(sibs, 'isSpecial');
      if (isAtRoot && !this.isSpecial && firstSpecial > -1 && idx > firstSpecial) {
        return SectionError.ORPHANED_SOURCE;
      }
    }

    // Next check for usage of legacy types
    if (this.sectiontype === SectionType.PAYMENT || this.appendixType === SectionType.PAYMENT) {
      return SectionError.LEGACY_PAYMENT;
    }
    if (this.sectiontype === SectionType.SCOPE || this.appendixType === SectionType.SCOPE) {
      return SectionError.LEGACY_SCOPE;
    }

    // This replaces the (ancient) Section.valid getter, which was not used anywhere
    if (this.indentLevel > 0 && this.isSummary && !get(this.parent, 'isSummary')) {
      return SectionError.ORPHANED_SUMMARY;
    }

    // Repeaters with prose template content but no dataSource configured are an error
    if (this.isRepeater && !this.dataSource && this.currentVersion.has('body')) {
      return SectionError.DC_REPEATER;
    }

    // AI sections need to be pointed to at least 1 other Section or Variables (depending on dsType)
    // to provide valid source material for the AI Prompt
    if (this.isAI) {
      // Vinnie blocks only require a type key to be set in order to point to one of the preset section types
      if (this.isVinnie) {
        if (!this.aiPrompt?.promptTypeKey) {
          return SectionError.AI_VINNIE_TYPE;
        } else {
          return null;
        }
      }
      if (this.aiPrompt?.dsType === DATA_SOURCE_TYPES.SECTIONS && !this.aiPrompt?.linkedSections.length) {
        return SectionError.DC_AI;
      } else if (this.aiPrompt?.dsType === DATA_SOURCE_TYPES.VARIABLES && !this.aiPrompt?.linkedVariables.length) {
        return SectionError.DC_AI;
      }
    }

    return null;
  }

  //page breaks are only supported for Paragraphs, Headers, Captions, and Columns
  get canPageBreak() {
    return this.sectiontype === SectionType.SOURCE || this.sectiontype === SectionType.HEADER;
  }
}
