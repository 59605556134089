import React from 'react';

import cx from 'classnames';

import { ButtonGroup, ControlLabel, FormGroup } from 'react-bootstrap';

import IndentationStyle, { INDENT_TYPES, INDENT_WRAPS } from '@core/models/IndentationStyle';

import { Button, Dropdown, Form, MenuItem, Switch } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

const IndentationStyleEditor = (props) => {
  const { levelIndent, readOnly } = props;
  const i = levelIndent.level;
  const onSave = props.onSave || (() => console.log('onSave not implemented'));
  const onReset = props.onReset || (() => console.log('onReset not implemented'));

  const [showResetConfirm, setShowResetConfirm] = React.useState(false);

  const activeType = levelIndent.type;
  const activeOverride = levelIndent.override;
  const activeWrap = INDENT_WRAPS.find((wrap) => wrap.key == levelIndent.wrap);
  const activeForcedLevel = INDENT_WRAPS.find((wrap) => wrap.key == levelIndent?.forcedLevel) || INDENT_WRAPS[0];

  const handleOnSave = (property, level, value) => {
    const indentation = {};

    switch (property) {
      case 'type':
        indentation.type = value;
        indentation.wrap = value === INDENT_TYPES.INDENT_HANGING ? Math.min(level, 8) : level - 1;
        if (value === INDENT_TYPES.INDENT_FIRST_LINE) indentation.wrap = level - 1;
        break;
      case 'wrap':
        indentation.wrap = value;
        break;
      case 'override':
        indentation.override = value;
        break;
      case 'forcedLevel':
        indentation.forcedLevel = value;
        break;
    }

    onSave(level, indentation);
  };

  const handleReset = (level) => {
    const indentation = new IndentationStyle({ level });
    indentation.wrap = level - 1;

    setShowResetConfirm(false);
    onReset(indentation, level);
  };

  return (
    <div key={`indentation-${i}`} className="page-style-container margins indentation" data-cy="page-style-indentation">
      <div className="page-style-title" data-cy="page-style-title">
        Level {i}
        <br />
        <span className="description">
          {showResetConfirm === i && (
            <>
              Are you sure you want to reset this level to default settings?
              <Button size="small" dmpStyle="link" onClick={() => setShowResetConfirm(false)} style={{ padding: 0 }}>
                Go back
              </Button>
              &nbsp;
            </>
          )}
          {!readOnly && (
            <Button
              size="small"
              dmpStyle="link"
              onClick={() => (showResetConfirm ? handleReset(i) : setShowResetConfirm(i))}
              style={{ padding: 0 }}
            >
              Reset
            </Button>
          )}
        </span>
      </div>
      <div className="page-style-body indentation">
        <Form>
          <div className="control-row">
            <FormGroup style={{ flexShrink: 2 }} className="complex">
              <ControlLabel>Type</ControlLabel>
              <ButtonGroup>
                <TooltipButton tip="Default">
                  <Button
                    iconOnly
                    icon="indentNone"
                    size="small"
                    onClick={() => handleOnSave('type', i, INDENT_TYPES.NONE)}
                    className={cx({ active: activeType === INDENT_TYPES.NONE })}
                  />
                </TooltipButton>

                <TooltipButton tip="First Line">
                  <Button
                    iconOnly
                    icon="indentFirstLine"
                    size="small"
                    onClick={() => handleOnSave('type', i, INDENT_TYPES.INDENT_FIRST_LINE)}
                    className={cx({ active: activeType === INDENT_TYPES.INDENT_FIRST_LINE })}
                  />
                </TooltipButton>

                <TooltipButton tip="Hanging">
                  <Button
                    iconOnly
                    icon="indentHanging"
                    size="small"
                    onClick={() => handleOnSave('type', i, INDENT_TYPES.INDENT_HANGING)}
                    className={cx({ active: activeType === INDENT_TYPES.INDENT_HANGING })}
                  />
                </TooltipButton>
              </ButtonGroup>
            </FormGroup>

            <FormGroup className="complex">
              <ControlLabel>Wrap</ControlLabel>
              <Dropdown
                id="margin-dropdown"
                onSelect={(wrap) => handleOnSave('wrap', i, wrap)}
                title={activeWrap?.title}
                block
                size="small"
              >
                {_.map(INDENT_WRAPS, (wrap, idx) => (
                  <MenuItem key={idx} eventKey={wrap.key}>
                    {wrap.title}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>
          </div>
          <div className="control-row">
            <FormGroup className="d-flex flex-row align-items-center">
              <Switch
                checked={activeOverride}
                id="border"
                onChange={(value) => handleOnSave('override', i, value)}
                size="small"
              >
                Override Level
              </Switch>
              {activeOverride && (
                <Dropdown
                  id="margin-dropdown"
                  onSelect={(wrap) => handleOnSave('forcedLevel', i, wrap)}
                  title={activeForcedLevel.title}
                  block
                  size="small"
                  disabled={!activeOverride}
                >
                  {_.map(
                    INDENT_WRAPS.filter((w) => w.key !== 'none'),
                    (wrap, idx) => (
                      <MenuItem key={idx} eventKey={wrap.key}>
                        {wrap.title}
                      </MenuItem>
                    )
                  )}
                </Dropdown>
              )}
            </FormGroup>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default IndentationStyleEditor;
